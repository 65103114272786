// import React from "react";
// import tw from "twin.macro";
// import styled from "styled-components";

// const JobSearchContainer = tw.div`w-full flex flex-col items-center lg:items-start`;
// const JobSearchInput = tw.input`w-full p-4 mt-4 rounded bg-white border border-gray-300 focus:outline-none focus:border-primary-500`;
// const JobSearchButton = tw.button`mt-4 px-8 py-3 bg-primary-500 text-white font-bold rounded shadow transition duration-300 hocus:bg-primary-700 hocus:text-gray-100 focus:shadow-outline`;

// const JobSearchBar = () => {
//   return (
//     <JobSearchContainer>
//       <JobSearchInput type="text" placeholder="Job Title or Keywords" />
//       <JobSearchInput type="text" placeholder="Location" />
//       <JobSearchButton>Search Jobs</JobSearchButton>
//     </JobSearchContainer>
//   );
// };

// export default JobSearchBar;

import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Server } from "App";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
const JobSearchContainer = styled.div`
  ${tw`w-full flex items-center bg-white rounded-full shadow p-2`}
`;

const JobSearchInput = styled.input`
  ${tw`flex-1 p-4 bg-transparent border-none focus:outline-none text-gray-600`}
`;

const JobSearchIcon = styled.span`
  ${tw`pl-4 text-gray-500`}
`;

const LocationInput = styled.input`
  ${tw`flex-1 p-4 bg-transparent border-l border-gray-300 focus:outline-none text-gray-600`}
`;

const ExperienceDropdown = styled.select`
  ${tw`flex-1 p-4 bg-transparent border-l border-gray-300 focus:outline-none text-gray-600`}
`;

const SearchButton = styled.button`
  ${tw`px-8 py-3 bg-blue-700 text-white font-bold rounded-full transition duration-300 hover:bg-purple-800 focus:shadow-outline`}
`;

const JobSearchBar = () => {
  const [industryData, SetIndustryData] = useState([]);
  const [jobTitle, setJobTitle] = useState("");
  const [city, setCity] = useState("");
  const [industry, setIndustry] = useState("");
  const naviagte = useNavigate();
  const fetchIndustries = async () => {
    try {
      const { data } = await axios.get(`${Server}/api/v1/data/industries`);
      // console.log(data.country_city);
      SetIndustryData(data.industries);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchIndustries();
    // console.log(industryData);.
  }, []);
  const search = async () => {
    try {
      let payload = {};
      if (jobTitle || city || industry) {
        if (jobTitle.length != 0) {
          payload.job = jobTitle;
        }
        if (city.length != 0) {
          payload.City = city;
        }
        if (industry.length != 0) {
          payload.Industry = industry;
        }
        // let payload = {
        //   job: jobTitle,
        //   City: city,
        //   Industry: industry,
        // };

        console.log(payload);
        naviagte("/find-jobs", { state: payload });
      } else {
        toast.error("Atleast one Field required");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <JobSearchContainer>
      <Toaster position="top-center" reverseOrder={false} />
      <JobSearchIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M12.9 14.32a8 8 0 111.414-1.414l5.387 5.386a1 1 0 01-1.415 1.415l-5.386-5.387zM8 14a6 6 0 100-12 6 6 0 000 12z"
            clipRule="evenodd"
          />
        </svg>
      </JobSearchIcon>
      <JobSearchInput
        type="text"
        placeholder="Search by Job Title"
        onChange={(e) => setJobTitle(e.target.value)}
      />
      <LocationInput
        type="text"
        placeholder="Location"
        onChange={(e) => setCity(e.target.value)}
      />
      <ExperienceDropdown onChange={(e) => setIndustry(e.target.value)}>
        <option> Select Industry</option>
        {industryData?.map((e, idx) => (
          <option key={idx} value={e}>
            {e}
          </option>
        ))}

        {/*<option>Fresher</option>
        <option>1-3 Years</option>
        <option>3-5 Years</option>
        <option>5+ Years</option>*/}
      </ExperienceDropdown>
      <SearchButton onClick={() => search()}>Search</SearchButton>
    </JobSearchContainer>
  );
};

export default JobSearchBar;
