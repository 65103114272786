import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Tag,
  Space,
  Input,
  Button,
  Select,
  Progress,
  Modal,
  Spin,
  Typography,
  Dropdown,
  Menu,
} from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Server } from "App";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { useSpring, animated } from "@react-spring/web";
import { DownOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { jwtDecode } from "jwt-decode";
import ForwardMail from "Components/ForwardMail";
import Mail from "../../Components/Mail";
const { Option } = Select;
const SkillTable = ({ skillMatchingTable }) => {
  // Parsing the input string into rows and columns
  const rows = parseTable(skillMatchingTable);

  return (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: "20px",
      }}
    >
      <thead>
        <tr>
          {rows[0].map((header, index) => (
            <th
              key={index}
              style={{ border: "1px solid #ddd", padding: "8px" }}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.slice(1).map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td
                key={cellIndex}
                style={{ border: "1px solid #ddd", padding: "8px" }}
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const parseTable = (tableString) => {
  // Split rows and columns, ignoring dashed lines
  const rows = tableString
    .trim()
    .split("\n")
    .filter((row) => !row.startsWith("|---"))
    .map((row) => row.split("|").map((cell) => cell.trim()));

  return rows;
};
const AiResumeSearch = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJobDetails, setSelectedJobDetails] = useState({});
  const [resumes, setResumes] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedAction, setSelectedAction] = useState("none");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [Bloader, setBLoader] = useState(false);
  const searchInput = useRef(null);
  const [status, setStatus] = useState("Completed");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [IsResultModalVisible, setIsResultModalVisible] = useState(false);
  const [aiResult, setAiResult] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [aiScore, setAiScore] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [fromEmail, setFromEmail] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedResumes, setSelectedResumes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [mailModalOpen, setMailModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleModalCancel = () => {
    setIsResultModalVisible(false);
  };

  const showMailModal = () => {
    setModalOpen(true);
  };
  const showForwardMailModal = () => {
    setMailModalOpen(true);
  };
  const scoreProps = useSpring({
    opacity: aiScore !== null ? 1 : 0,
    transform: aiScore !== null ? "scale(1)" : "scale(0.8)",
    config: { duration: 500 },
  });

  // useEffect(() => {
  //   if (location.state) {
  //     const { jobdetails } = location.state;
  //     setSelectedJobDetails(jobdetails);
  //     setSelectedAction(jobdetails?.jobId);
  //   }
  // }, [location.state]);

  // useEffect(() => {
  //   if (location.state) {
  //     SearchWithAI(selectedJobDetails);
  //     navigate(location.pathname, { replace: true, state: {} });
  //   }
  // }, [selectedAction, selectedJobDetails]);
  // console.log(selectedAction);
  // console.log(selectedJobDetails);
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setFromEmail(decodedToken?.email);
    }
    console.log(fromEmail);
  }, []);
  let emails = [];
  let selectedCandidate = [];
  useEffect(() => {
    selectedRowKeys.map((e, idx) => {
      // console.log(sortedCandidates[e].email);
      console.log(resumes[e]);
      selectedCandidate.push(resumes[e].resume);
      emails.push(resumes[e].email);
      setSelectedEmails(emails);
      setSelectedResumes(selectedCandidate);
    });
  }, [selectedRowKeys]);
  let addedCandidates = [];
  useEffect(() => {
    selectedRowKeys.map((e, idx) => {
      addedCandidates.push(resumes[e]);
      setSelectedUser(addedCandidates);
    });
  }, [selectedRowKeys]);
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleActionChange = (value) => {
    setSelectedAction(value);
    console.log(value);

    if (value != "none") {
      const selectedJob = jobs.find((job) => job.jobId === value);
      setSelectedJobDetails(selectedJob);
      // console.log(selectedJob);
    } else if (value === "none") {
      setSelectedJobDetails({});
    }
  };
  // console.log(selectedJobDetails);

  const getJobs = async () => {
    try {
      let userId = localStorage.getItem("UserId");
      const { data } = await axios.get(
        `${Server}/api/v1/employer/jobs/get/${userId}`
      );

      setJobs(data.jobs);
      // console.log(data.jobs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getJobs();
  }, []);
  // console.log(selectedJobDetails);

  const SearchWithAI = async (jobdetails) => {
    console.log("loading");

    try {
      if (selectedAction != "none") {
        setStatus("Searching");
        setLoading(true);
        let formData = {
          title: selectedJobDetails?.JobTitle,
          description: selectedJobDetails?.JobDescription,
          skills: selectedJobDetails?.jobSkills,
          keys_and_responsibilities: selectedJobDetails?.Responsibilties,
          experience: `${selectedJobDetails?.Experience}`,
          location: selectedJobDetails?.Country,
        };
        console.log(formData);

        let { data } = await axios.post(
          `${Server}/api/v1/search/keyword/ai/search/candidate`,
          formData
        );
        console.log(data.candidates);
        data?.candidates?.sort((a, b) => a.rank - b.rank);
        let candidates = [];
        data?.candidates?.map((e, idx) =>
          candidates.push({
            key: idx,
            rank: e.rank,
            name: e.name ? e.name : "User",
            position: e.jobTitle,
            experience: e.experience,
            score: e.matching_percentage, // Score percentage for each resume
            resume: e.resume,
            location: e.country ? `${e.country}` : "Not provided",
            summary: e.summary,
            education: e.Education,
            work_experience: e.work_experience,
            skills: e.skills,
            _id: e._id,
          })
        );
        setResumes(candidates);
        setStatus("Completed");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.message === "No results Found") {
        setLoading(false);
        setStatus("Completed");
        setResumes([]);
      } else {
        setLoading(false);
        setStatus("Pending");
      }
    }
  };
  const parseTableMarkdown = (markdown) => {
    const lines = markdown.trim().split("\n");
    const headers = lines[0]
      .split("|")
      .map((cell) => cell.trim())
      .filter((cell) => cell);
    const rows = lines.slice(2).map((line) =>
      line
        .split("|")
        .map((cell) => cell.trim())
        .filter((cell) => cell)
    );
    return [headers, ...rows];
  };

  const downloadPDF = async () => {
    const doc = new jsPDF();
    const margin = 10;
    const pageWidth = doc.internal.pageSize.getWidth() - margin * 2;
    let yPosition = 20;

    // Title
    doc.setFontSize(18);
    doc.text("AI Analysis Result", margin, yPosition);
    yPosition += 10;

    // Add Brief Summary with Heading
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Brief Summary:", margin, yPosition);
    yPosition += 10;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const briefSummary = aiResult.brief_summary;
    const briefLines = doc.splitTextToSize(briefSummary, pageWidth);
    briefLines.forEach((line) => {
      if (yPosition + 10 > doc.internal.pageSize.height) {
        doc.addPage();
        yPosition = margin;
      }
      doc.text(line, margin, yPosition);
      yPosition += 10;
    });

    // Add Short Summary with Heading
    if (yPosition + 10 > doc.internal.pageSize.height) {
      doc.addPage();
      yPosition = margin;
    }
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Short Summary:", margin, yPosition);
    yPosition += 10;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const shortSummary = aiResult.short_summary;
    const shortLines = doc.splitTextToSize(shortSummary, pageWidth);
    shortLines.forEach((line) => {
      if (yPosition + 10 > doc.internal.pageSize.height) {
        doc.addPage();
        yPosition = margin;
      }
      doc.text(line, margin, yPosition);
      yPosition += 10;
    });

    // Add Matching Score with Heading
    if (yPosition + 10 > doc.internal.pageSize.height) {
      doc.addPage();
      yPosition = margin;
    }
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Matching Score:", margin, yPosition);
    yPosition += 10;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const aiScore = aiResult.matching_score;
    doc.text(`Score: ${aiScore}`, margin, yPosition);
    yPosition += 10;

    // Add Skill Matching Table with Heading
    const skillMatchingTableMarkdown = aiResult.skill_matching_table;
    const skillMatchingTable = parseTableMarkdown(skillMatchingTableMarkdown);

    if (Array.isArray(skillMatchingTable) && skillMatchingTable.length > 0) {
      if (yPosition + 10 > doc.internal.pageSize.height) {
        doc.addPage();
        yPosition = margin;
      }

      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text("Skill Matching Table:", margin, yPosition);
      yPosition += 10;

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.autoTable({
        startY: yPosition,
        head: [skillMatchingTable[0]], // Header
        body: skillMatchingTable.slice(1), // Rows
        margin: { top: 10 },
      });
    } else {
      console.error("Invalid skill matching table data:", skillMatchingTable);
    }

    // Save the PDF
    doc.save(`analysis-result.pdf`);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleExpand = (key) => {
    const isExpanded = expandedRowKeys.includes(key);
    const newExpandedRowKeys = isExpanded
      ? expandedRowKeys.filter((k) => k !== key)
      : [...expandedRowKeys, key];
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const columns = [
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      // sorter: (a, b) => a.rank.localeCompare(b.rank),
      // // ...getColumnSearchProps("rank"),
      width: "8%", // Adjusted width
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name"),
      width: "15%", // Adjusted width
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      sorter: (a, b) => a.jobTitle.localeCompare(b.jobTitle),
      ...getColumnSearchProps("position"),
      width: "15%", // Adjusted width
    },
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
      sorter: (a, b) => {
        const yearsA = parseInt(a.experience.split(" ")[0], 10);
        const yearsB = parseInt(b.experience.split(" ")[0], 10);
        return yearsA - yearsB;
      },
      ...getColumnSearchProps("experience"),
      width: "10%", // Adjusted width
    },
    {
      title: "Country",
      dataIndex: "location",
      key: "location",
      width: "15%", // Adjusted width
    },
    {
      /*
      title: "Skills",
      key: "skills",
      dataIndex: "skills",
      render: (skills) => (
        <>
          {skills.map((skill) => {
            let color = skill.length > 6 ? "geekblue" : "green";
            if (skill === "JavaScript") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={skill}>
                {skill.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
      width: "25%", // Adjusted width
   */
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      render: (score, record) => (
        <div className="progress-container">
          {" "}
          {/* Add a container div for styling */}
          {console.log(score)}
          {record.isAnalysing === "Analysed" ? (
            <Progress
              type="circle"
              percent={score}
              width={80} // Size of the circle
              format={(percent) => `${percent}%`} // Show percentage in center
              strokeColor={{
                "0%": "#0057e7",
                "100%": "#34b1eb",
              }}
              className="blue-shadow" // Custom class for shadow effect
            />
          ) : (
            <p>Not Analysed</p>
          )}
        </div>
      ),
      // sorter: (a, b) => a.score - b.score,
      width: "15%", // Adjusted width
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        const fileExtension = record?.resume?.split(".").pop().toLowerCase();
        const isDocx = fileExtension === "docx";
        const fileUrl = isDocx
          ? `https://docs.google.com/viewerng/viewer?url=${record.resume}`
          : `https://docs.google.com/viewerng/viewer?url=${record.resume}`;

        return (
          <Space size="middle">
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              {record.resume ? <Button>View CV</Button> : "No CV"}
            </a>
            {record.isAnalysing === "Analysed" ? (
              <>
                <Button onClick={() => setIsResultModalVisible(true)}>
                  View Results
                </Button>
              </>
            ) : (
              <Button onClick={() => handleModalOk(record)}>
                {record.isAnalysing === "Analysing" ? (
                  <>
                    <LoadingOutlined />
                    <span>Analysing</span>
                  </>
                ) : (
                  <>
                    <SearchOutlined />
                    <span>Analyse</span>
                  </>
                )}
              </Button>
            )}
          </Space>
        );
      },
    },
    {
      /*
      title: "Last Updated",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      sorter: (a, b) => new Date(a.lastUpdated) - new Date(b.lastUpdated),
      ...getColumnSearchProps("lastUpdated"),
      width: "15%", // Adjusted width
   */
    },
  ];
  // console.log(candidates);
  const handleModalOk = async (selectedUser) => {
    const updatedData = resumes.map((item) =>
      item.key === selectedUser.key
        ? { ...item, isAnalysing: "Analysing" }
        : item
    );
    setResumes(updatedData);
    setLoader(true);
    try {
      console.log(selectedUser);

      let userId = localStorage.getItem("UserId");
      let bodyData = {
        jobDetails: selectedJobDetails,
        userDetails: {
          basic_info: {
            full_name: selectedUser.name,
            Country: selectedUser.location,
            Experience_in_Years: selectedUser.experience,
            Job_Title: selectedUser.position,
          },
          education: selectedUser.education,
          work_experience: selectedUser.work_experience,
          skills: selectedUser.skills,
        },
      };
      console.log(bodyData);

      const { data } = await axios.post(
        `${Server}/api/v1/employer/jobs/openAI/aiResume/analyse/${userId}/${selectedJobDetails.jobId}/${selectedUser._id}`,
        bodyData
      );
      console.log(data);
      setAiResult(data.data);
      setAiScore(data.data.matching_score);
      setLoader(false);
      const updatedDataAfterAnalysis = updatedData.map((item) =>
        item.key === selectedUser.key
          ? {
              ...item,
              isAnalysing: "Analysed",
              score: parseInt(data.data.matching_score,10),
            }
          : item
      );
      setResumes(updatedDataAfterAnalysis);
      setIsResultModalVisible(true);
      console.log(updatedDataAfterAnalysis);
    } catch (error) {
      // message.error("Failed to analyze. Please try again."); // Error handling
      console.log("Error analyzing with AI:", error);
      setLoader(false);
      const updatedDataAfterAnalysis = updatedData.map((item) =>
        item.key === selectedUser.key ? { ...item, isAnalysing: false } : item
      );
      setResumes(updatedDataAfterAnalysis);
    }
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const nestedMenu = (
    <Menu>
      {jobs.map((e, idx) => (
        <Menu.Item key={`1-${idx + 1}`}>
          <Button loading={Bloader} onClick={() => shortlistedJob(e)}>
            {e?.JobTitle}
          </Button>
        </Menu.Item>
      ))}
    </Menu>
  );

  const shortlistedJob = async (e) => {
    e.preventDefault();
    try {
      setBLoader(true);
      // if (token) {
      let userId = localStorage.getItem("UserId");
      let jobDetails = {
        jobId: selectedJobDetails?.jobId,
        candidates: selectedUser,
        // jobId: params.jobId,
        // jobTitle: jobData[0]?.JobTitle,
        // companyName: jobData[0]?.companyName,
        // categories: jobData[0]?.Industry,
        // city: jobData[0]?.City,
        // country: jobData[0]?.Country,
        // logo: jobData[0]?.image,
        // empId: params.userId,
        // status: jobData[0]?.status,
        // email: jobData[0]?.EmailAddress,
      };
      console.log(jobDetails);

      const { data } = await axios.post(
        `${Server}/api/v1/employer/shortlistResume/add/${userId}`,
        jobDetails
      );
      setBLoader(false);
      toast.success("Added to job");
      // } else {
      //   toast.error("Please login");
      //   showModal("login");
      // }
    } catch (error) {
      setBLoader(false);
      if (error.response.data.message === "Job already shortlisted") {
        toast.error("Already shortlisted");
      } else {
        console.log(error.response.data.message);
        toast.error("Error in Shortlisting the job");
      }
    }
  };
  const items = [
    {
      label: (
        <a
          // type="primary"
          disabled={!hasSelected}
          onClick={showMailModal}
          // loading={loading}
        >
          Send Email
        </a>
      ),
      key: "0",
    },
    {
      key: "1",
      label: (
        <a onClick={shortlistedJob}>{Bloader ? "Adding.." : "Add to Job"}</a>
      ),
    },
    {
      key: "2",
      label: <a onClick={showForwardMailModal}>Forward Resume </a>,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Toaster position="top-center" reverseOrder={false} />

      <h2
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          width: "100%",
          height: "150px",
          marginBottom: "20px",
          padding: "10px",
          backgroundImage:
            "linear-gradient(45deg, #0A0A23, #000000 40%, #1E90FF 80%)",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "5px",
        }}
      >
        AI Resume Search
      </h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Select
          defaultValue="none"
          style={{
            width: 200,
            marginRight: "10px",
            marginTop: "18px",
          }}
          onChange={handleActionChange}
          virtual={false}
        >
          <Option value="none">Select Job</Option>
          {jobs?.map((e, idx) => (
            <Option key={idx} value={e?.jobId} onChange={handleActionChange}>
              {e?.JobTitle}
            </Option>
          ))}
        </Select>

        <div className="loader-one">
          {loading ? (
            <>
              <span className="loader__inner1"></span>
              <span className="loader__inner1"></span>
              <span className="loader__text1">AI</span>
            </>
          ) : (
            <Button
              style={{
                backgroundColor: "#1E90FF", // SkyBlue color
                color: "#fff", // White text color
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease",
              }}
              onClick={SearchWithAI}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#4682B4")
              } // Darker SkyBlue on hover
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#1E90FF")
              } // Original color on mouse out
            >
              Search with AI
            </Button>
          )}
        </div>
      </div>

      {status === "Pending" && (
        <p style={{ padding: "100px", fontSize: "30px" }}>
          {" "}
          Select a Job & Search With AI{" "}
        </p>
      )}
      {status === "Searching" && (
        <p style={{ padding: "100px" }}> Searching..... </p>
      )}
      {status === "Completed" && (
        <>
          {" "}
          <div style={{ marginBottom: 16 }}>
            <Dropdown
              menu={{ items }}
              disabled={!hasSelected}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Button
                  type="primary"
                  disabled={!hasSelected}
                  // loading={loading}
                >
                  Actions
                  <DownOutlined />
                </Button>
              </a>
            </Dropdown>
            <span style={{ marginLeft: 8 }}>
              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
            </span>
            {modalOpen && (
              <div>
                <Mail
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  showMailModal={showMailModal}
                  selectedEmails={selectedEmails}
                  fromEmail={fromEmail}
                />
              </div>
            )}
            {mailModalOpen && (
              <div>
                <ForwardMail
                  modalOpen={mailModalOpen}
                  setModalOpen={setMailModalOpen}
                  showMailModal={showForwardMailModal}
                  selectedResume={selectedResumes}
                  fromEmail={fromEmail}
                />
              </div>
            )}
          </div>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={resumes}
            pagination={{ pageSize: 10 }}
            style={{
              width: "100%",
              maxWidth: "1200px", // Max width for the table
              backgroundColor: "white",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          />
        </>
      )}
      <Modal
        title="AI Analysis Result"
        open={IsResultModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="download" onClick={() => downloadPDF()}>
            Download as PDF
          </Button>,
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
        ]}
        centered
        styles={{ textAlign: "center" }}
      >
        <Spin spinning={loading}>
          {/*<div id="modal-content">
            {aiScore !== null && (
              <animated.div style={scoreProps} className="score-box">
                <span>Score:</span> {aiScore}
              </animated.div>
            )}
            <ReactMarkdown style={{ marginBottom: 20 }}>
              {aiResult}
            </ReactMarkdown>
          </div>*/}
          <div id="modal-content">
            {/* <ReactMarkdown style={{ marginBottom: 20 }}>
              {aiResult?.brief_summary}
            </ReactMarkdown>
            <ReactMarkdown style={{ marginBottom: 20 }}>
              {aiResult?.matching_score}
            </ReactMarkdown>
            <ReactMarkdown style={{ marginBottom: 20 }}>
            {aiResult?.short_summary}
          </ReactMarkdown> */}
            <label style={{ marginBottom: "10px", fontWeight: "bolder" }}>
              Brief Summary:
            </label>
            <Typography style={{ marginBottom: "20px" }}>
              {aiResult?.brief_summary}
            </Typography>
            <label style={{ marginBottom: "10px", fontWeight: "bolder" }}>
              Short Summary:
            </label>
            <Typography style={{ marginBottom: "20px" }}>
              {" "}
              {aiResult?.short_summary}
            </Typography>
            <label style={{ marginBottom: "10px", fontWeight: "bolder" }}>
              Skill Matching:
            </label>
            <SkillTable skillMatchingTable={aiResult.skill_matching_table} />
            {aiScore !== null && (
              <animated.div style={scoreProps} className="score-box">
                <span>Score:</span> {aiScore}
              </animated.div>
            )}
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default AiResumeSearch;
{
  /* <Table
          columns={columns}
          dataSource={resumes}
          pagination={{ pageSize: 10 }}
          expandedRowKeys={expandedRowKeys}
          onExpand={(expanded, record) => handleExpand(record.key)}
          expandedRowRender={(record) => (
            <p style={{ margin: 0 }}>{record.summary}</p>
          )}
          style={{
            width: "100%",
            maxWidth: "1200px", // Max width for the table
            backgroundColor: "white",
            borderRadius: "8px",
            overflow: "hidden",
          }}
          rowKey="key"
        />*/
}
