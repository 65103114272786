import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "antd";

const PracticeAssessment = () => {
  const navigate = useNavigate();
  const [practiceAssessment, setPracticeAssessment] = useState([
   
  ]);

  const handleNavigate = () => {
    navigate("/practicalAssessment"); // Update this route to your actual path
  };

  return (
    <div>
      <section className="user-dashboard">
        <div className="dashboard-outer">
          <div className="upper-title-box">
            <h3>Practice Video Assessment</h3>
            <div className="text">Ready to jump back in?</div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            {" "}
            <button onClick={handleNavigate} className="btn btn-primary">
              Practice Video Assessment
            </button>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="ls-widget">
                <div className="tabs-box">
                  <div className="widget-title">
                    <h4>My Practice Video Assessment</h4>

                    <div className="chosen-outer">
                      <select className="chosen-select">
                        <option>Last 6 Months</option>
                        <option>Last 12 Months</option>
                        <option>Last 16 Months</option>
                        <option>Last 24 Months</option>
                        <option>Last 5 Years</option>
                      </select>
                    </div>
                  </div>

                  <div className="widget-content">
                    <div className="table-outer">
                      {practiceAssessment.length === 0 ? (
                        <p className="text-center">No Assessments</p>
                      ) : (
                        <table className="default-table manage-job-table">
                          <thead>
                            <tr>
                              <th>Job Title</th>
                              <th>Created</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {practiceAssessment.map((e, subIdx) => (
                              <tr key={subIdx}>
                                <td>
                                  <h6>{e.JobTitle}</h6>
                                  <span className="info">
                                    <i className="icon flaticon-map-locator"></i>{" "}
                                    {e.City}, {e.Country}
                                  </span>
                                </td>
                                <td>{e.postedTime}</td>
                                <td>
                                  <div className="option-box">
                                    <ul className="option-list">
                                      <li>
                                        <button
                                          data-text="View Result"
                                          onClick={() =>
                                            navigate(
                                              `/job-detail/${e.JobTitle}`
                                            )
                                          }
                                        >
                                          <span className="la la-eye"></span>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="copyright-text">
        <p>© 2024 Skill Stream. All Right Reserved.</p>
      </div>
    </div>
  );
};

export default PracticeAssessment;
