import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Pricing.css"; // Assuming you save the CSS separately
import { loadStripe } from "@stripe/stripe-js";
import { Content } from "antd/es/layout/layout";
import Login from "../../Pages/Auth/Login";
import Register from "../../Pages/Auth/Register";
import ConfirmModal from "../../Pages/Auth/ConfirmModal";
import ForgotPassword from "../../Pages/Auth/ForgotPassword";
import axios from "axios";
import { Server } from "App";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Pricing = () => {
  const [plan, setPlan] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("login");
  const [confirmModal, setConfirmModal] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);
  const navigate = useNavigate();
  let role = localStorage.getItem("Role");
  const showModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
    // if (type === "login") {
    //   // Your login logic goes here
    //   // Assuming login is successful, redirect to candidates-list
    //   navigate("/candidates-list");
    // }
  };
  console.log(process.env.REACT_APP_STRIPE_API_KEY);
  let token = localStorage.getItem("token");
  let userId = localStorage.getItem("UserId");

  const subscribe = async (plan, price, priceId) => {
    try {
      if (token) {
        if (plan === "Free-trial") {
          let body = {
            plan: plan,
          };
          console.log(plan);

          const { data } = await axios.post(
            `${Server}/api/v1/subscribe/new/${userId}`,
            body
          );
          navigate("/employer-dashboard");
        } else {
          const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
          const body = {
            plan: plan,
            amount: price,
            priceId: priceId,
          };
          const headers = {
            Content: "application/json",
          };
          const { data } = await axios.post(
            `${Server}/api/v1/subscribe/create/checkout-session`,
            body,
            {
              Headers: headers,
            }
          );
          // console.log(response);
          // const session = await response.json();
          const result = stripe.redirectToCheckout({
            sessionId: data.id,
          });
        }
      } else {
        showModal("login");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Helmet>
        <meta
          name="Job Posting Pricing | Start from $49 | Free Trial Available | Skillstream Jobs"
          content="Explore SkillStream Jobs pricing for job postings starting from $49. Enjoy a free trial and see how our affordable plans can streamline your hiring process."
        />
      </Helmet>
      <section className="page-title">
        <div className="auto-container">
          <div className="title-outer">
            <h1>Pricing</h1>
            <ul className="page-breadcrumb">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Pricing</li>
            </ul>
          </div>
        </div>
      </section>
      <section id="pricing" className="pricing-content section-padding">
        <div className="container">
          <div className="section-title text-center">
            <h2 style={{ color: "#1E90FF" }}>Job Advertising package</h2>
            <p style={{ color: "#1E90FF" }}>
              Choose the best package to post your job openings and attract the
              best candidates.
            </p>
          </div>
          <div className="row text-center">
            <div
              className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.1s"
              data-wow-offset="0"
            >
              <div className="pricing_design">
                <div className="single-pricing">
                  <div className="price-head">
                    <h2>Free Trail</h2>
                    <h1>$0</h1>
                    {/* <span>/Per Job</span> */}
                  </div>
                  <ul>
                    <li>
                      <b>1</b> Job Posting
                    </li>

                    <li>
                      <b>7</b> Days Visibility
                    </li>

                    <li>
                      14 days applicable on AI Premium Package , Standard and
                      Premium Package
                    </li>
                    <li>
                      <b>Email</b> Support
                    </li>
                  </ul>
                  <div className="pricing-price"></div>
                  <button
                    // href="#"
                    className="price_btn"
                    // onClick={() =>
                    //   setPlan([
                    //     {
                    //       plan: "Free Trail",
                    //       price: "0",
                    //     },
                    //   ])
                    // }
                    onClick={() => subscribe("Free-trial", "0")}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.1s"
              data-wow-offset="0"
            >
              <div className="pricing_design">
                <div className="single-pricing">
                  <div className="price-head">
                    <h2>Basic</h2>
                    <h1>$49</h1>
                    {/* <span>/Per Job</span> */}
                  </div>
                  <ul>
                    <li>
                      <b>1</b> Job Posting
                    </li>
                    <li>
                      <b>30</b> Days Visibility
                    </li>
                    <li>
                      <b>AI</b> Fitment Analysis
                    </li>
                    <li>
                      <b>AI</b> Video Assesments
                    </li>
                    <li>
                      <b>Sourcing support </b> from Skillstream team
                    </li>
                    {/* <li>
                      <b>Email</b> Support
                    </li> */}
                  </ul>
                  <div className="pricing-price"></div>
                  <button
                    // href="#"
                    className="price_btn"
                    // onClick={() =>
                    //   setPlan([
                    //     {
                    //       plan: "Basic",
                    //       price: "49",
                    //     },
                    //   ])
                    // }
                    onClick={() =>
                      subscribe("Basic", "49", "price_1Q2HomE6AeqDfCrWT2qFSNqL")
                    }
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-wow-offset="0"
            >
              <div className="pricing_design">
                <div className="single-pricing">
                  <div className="price-head">
                    <h2>Standard</h2>
                    <h1 className="price">$99</h1>
                    {/* <span>/Per Job</span> */}
                  </div>
                  <ul>
                    <li>
                      <b>3</b> Job Postings
                    </li>
                    <li>
                      <b>30</b> Days Visibility
                    </li>
                    <li>
                      <b>AI</b> Fitment Analysis
                    </li>
                    <li>
                      <b>AI</b> Video Assesments
                    </li>
                    <li>
                      <b>Sourcing support </b> from Skillstream team
                    </li>
                    {/* <li>
                      <b>Email</b> Support
                    </li> */}
                  </ul>
                  <div className="pricing-price"></div>
                  <button
                    // href="#"
                    className="price_btn"
                    // onClick={() =>
                    //   setPlan([
                    //     {
                    //       plan: "Standard",
                    //       price: "129",
                    //     },
                    //   ])
                    // }
                    onClick={() =>
                      subscribe(
                        "Standard",
                        "99",
                        "price_1Q2HqME6AeqDfCrWl93yUURX"
                      )
                    }
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 col-xs-12 mt-5 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
              data-wow-offset="0"
            >
              <div className="pricing_design">
                <div className="single-pricing">
                  <div className="price-head">
                    <h2>Premium</h2>
                    <h1 className="price">$189</h1>
                    {/* <span>/Per Job</span> */}
                  </div>
                  <ul>
                    <li>
                      <b>Unlimited</b> Job Postings
                    </li>
                    <li>
                      <b>Unlimited</b> Access to the Database
                    </li>
                    <li>
                      <b>Resume Search</b>{" "}
                    </li>
                    <li>
                      <b>AI</b> Fitment Analysis
                    </li>
                    <li>
                      <b>AI</b> Video Assesments
                    </li>
                    <li>
                      <b>30</b> Days Visibility
                    </li>

                    <li>
                      <b>Sourcing support </b>
                      from Skillstream team
                    </li>
                    {/* <li>
                      <b>Email</b> Support
                    </li> */}
                  </ul>
                  <div className="pricing-price"></div>

                  <button
                    // href="#"
                    className="price_btn"
                    onClick={() =>
                      subscribe(
                        "Premium",
                        "189",
                        "price_1Q2HrIE6AeqDfCrWmzwQ0ijO"
                      )
                    }
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {forgotModal && (
        <ForgotPassword
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setModalType={setModalType}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          confirmModal={confirmModal}
          setConfirmModal={setConfirmModal}
        />
      )}
      {isModalOpen && (
        <div>
          {modalType === "login" ? (
            <div>
              {console.log(modalType)}
              <Login
                isModalOpen={isModalOpen}
                modalType={modalType}
                setIsModalOpen={setIsModalOpen}
                setModalType={setModalType}
                showModal={showModal}
                confirmModal={confirmModal}
                setConfirmModal={setConfirmModal}
                forgotModal={forgotModal}
                setForgotModal={setForgotModal}
              />
            </div>
          ) : (
            <Register
              isModalOpen={isModalOpen}
              modalType={modalType}
              setIsModalOpen={setIsModalOpen}
              setModalType={setModalType}
            />
          )}
        </div>
      )}
      <section id="pricing" className="pricing-content section-padding">
        <div className="container">
          <div className="section-title text-center">
            <h2 style={{ color: "1E90FF" }}>Job posting and Resume Search</h2>
            <p style={{ color: "#1E90FF" }}>
              Choose the best package to post your job openings and attract the
              best candidates.
            </p>
          </div>
          <div className="row justify-content-center text-center">
            <div
              className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-wow-offset="0"
            >
              <div className="pricing_design">
                <div className="single-pricing">
                  <div className="price-head">
                    <h2>Jobs with AI Search</h2>
                    <h1 className="price">$249</h1>
                    <span>/Per Month</span>
                  </div>
                  <ul>
                    <li>Unlimited Job Postings</li>
                    <li>
                      Unlimited access to our Database <br /> (Available only
                      for Canada)
                    </li>
                    <li>AI Video Interview</li>
                    <li>AI Resume Search</li>
                    <li>AI Fitment Analysis</li>
                  </ul>
                  <div className="pricing-price"></div>
                  <button
                    // href="#"
                    className="price_btn"
                    onClick={() =>
                      subscribe(
                        "Recommended",
                        "249",
                        "price_1Q2HrxE6AeqDfCrWhn3m489g"
                      )
                    }
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
            {/* 
            <div
              className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-wow-offset="0"
            >
              <div className="pricing_design">
                <div className="single-pricing">
                  <div className="price-head">
                    <h2>Recommended</h2>
                    <h1 className="price">$299</h1>
                    <span>/Per Month</span>
                  </div>
                  <ul>
                    <li>
                      <b>Unlimited</b> access to Database
                    </li>
                    <li>
                      <b>Unlimited</b> job posting
                    </li>
                    <li>
                      {" "}
                      <b>Unlimited</b> Resume Search
                    </li>

                    <li>
                      <b>Email</b> Support
                    </li>
                  </ul>
                  <div className="pricing-price"></div>
                  <a
                    onClick={() =>
                      subscribe(
                        "Recommended",
                        "299",
                        "price_1PdWn2P218aRnkr8eMEL98xa"
                      )
                    }
                    className="price_btn"
                  >
                    Subscribe
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
