import React, { useEffect, useState } from "react";
import $ from "jquery";
import "jquery-ui/ui/widgets/slider";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Server } from "../../App";
import { Skeleton } from "antd";
import { formatDistanceToNow } from "date-fns";
import { Helmet } from "react-helmet";

const JobList = () => {
  const navigate = useNavigate();
  const [sliderValues, setSliderValues] = useState([0, 1500]);
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage, setJobsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("New Jobs");
  const [industryData, SetIndustryData] = useState([]);
  const [cityData, SetCityData] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [city, SetCity] = useState("");
  const [industry, SetIndustry] = useState("");
  const [jobTitle, SetjobTitle] = useState([]);
  const [loader, setLoader] = useState(false);
  const [kewywordData, setKeywordData] = useState(false);
  const location = useLocation();
  const [jobType, setJobType] = useState([]); // New State for Job Type Filter
  const [experienceLevel, setExperienceLevel] = useState(""); // Experience Level Filter
  const [datePosted, setDatePosted] = useState(""); // Date Posted Filter
  const { City, job, Industry } = location.state || {};

  useEffect(() => {
    if (location.state) {
      const keyword = async () => {
        SetjobTitle(job);
        SetCity(City);
        SetIndustry(Industry);
        try {
          let keywordData = {};

          // Push job title(s) to the keywordData array if available
          if (job) {
            keywordData.jobTitle = job;
          }

          // Push city to the keywordData array if available
          if (City) {
            keywordData.city = City;
          }

          // Push categories to the keywordData array if available
          if (Industry) {
            keywordData.industry = Industry;
          }

          const { data } = await axios.post(
            `${Server}/api/v1/search/keyword/employer/jobs`,

            keywordData
          );
          console.log(data);
          // console.log(keywordData);
          // const jobsArray = data.results.reduce(
          //   (acc, curr) => acc.concat(curr.jobs),
          //   []
          // );

          // Update the job list with search results
          setJobs(data.results);
          setKeywordData(true);
          SetjobTitle("");
          SetCity("");
          SetIndustry("");
        } catch (error) {
          console.log(error);
          toast.error("Error");
        }
      };
      keyword();
    } else {
      // console.log("no state");
      getAllJobs();
      setKeywordData(false);
    }
  }, []);

  useEffect(() => {
    // Scroll to the top when navigation occurs
    window.scrollTo(0, 0);
  }, []);
  let token = localStorage.getItem("token");
  let role = localStorage.getItem("Role");
  // console.log(token);
  const params = useParams();
  const getAllJobs = async () => {
    try {
      const { data } = await axios.get(
        `${Server}/api/v1/employer/jobs/all-jobs`
      );
      // console.log({ data: data.jobs });
      setJobs(data.getAllJobs);
    } catch (error) {
      console.log(error);
    }
  };
  let userId = localStorage.getItem("UserId");
  const keywordSearch = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      let keywordData = {};

      // Push job title(s) to the keywordData array if available
      if (jobTitle.length != 0) {
        keywordData.jobTitle = jobTitle;
      }

      // Push city to the keywordData array if available
      if (city.length != 0) {
        keywordData.city = city;
      }

      // Push categories to the keywordData array if available
      if (industry.length != 0 || industry === "All Categories") {
        keywordData.industry = industry;
      }

      const { data } = await axios.post(
        `${Server}/api/v1/search/keyword/employer/jobs`,

        keywordData
      );
      console.log(data);
      // console.log(keywordData);
      // const jobsArray = data.results.reduce(
      //   (acc, curr) => acc.concat(curr.jobs),
      //   []
      // );

      // Update the job list with search results
      setJobs(data.results);
      setKeywordData(true);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
      setJobs([]);
    }
  };

  const shortlistedJob = async (job) => {
    try {
      let jobDetails = {
        jobId: job.jobId,
        jobTitle: job.JobTitle,
        companyName: job.companyName,
        categories: job.Industry,
        city: job.City,
        country: job.Country,
        logo: job.image,
      };
      console.log(jobDetails);
      const { data } = await axios.post(
        `${Server}/api/v1/candidate/shortlistJob/add/${userId}`,
        jobDetails
      );
      console.log(data);
      toast.success("Shortlisted Successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error in Shortlisting the job");
    }
  };
  const fetchIndustries = async () => {
    try {
      const { data } = await axios.get(`${Server}/api/v1/data/industries`);
      // console.log(data.country_city);
      SetIndustryData(data.industries);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchIndustries();
  }, []);
  // useEffect(() => {
  //   if (token === null) {
  //     toast.error("Please login");
  //     setTimeout(() => {
  //       navigate("/");
  //     }, 300);
  //   }
  // }, []);

  useEffect(() => {
    // Slider for salary range
    if ($(".salary-range-slider").length) {
      $(".salary-range-slider").slider({
        range: true,
        min: 0,
        max: 20000,
        values: sliderValues,
        slide: function (event, ui) {
          setSliderValues(ui.values);
        },
      });

      // Initial values
      $(".salary-amount .min").text(sliderValues[0]);
      $(".salary-amount .max").text(sliderValues[1]);
    }
  }, [sliderValues]);

  const sortJobs = (jobs) => {
    // Implement sorting logic based on sortBy state
    // For example, you can sort by job title, salary, etc.
    let sortedJobs = [...jobs];
    if (sortBy === "New Jobs") {
      sortedJobs.sort((a, b) => new Date(b.time) - new Date(a.time));
    } else if (sortBy === "Freelance") {
      sortedJobs = sortedJobs.filter((job) => job.JobType === "Freelance");
    } else if (sortBy === "Full Time") {
      sortedJobs = sortedJobs.filter((job) => job.JobType === "Full Time");
    } else if (sortBy === "Internship") {
      sortedJobs = sortedJobs.filter((job) => job.JobType === "Internship");
    } else if (sortBy === "Part Time") {
      sortedJobs = sortedJobs.filter((job) => job.JobType === "Part Time");
    } else if (sortBy === "Temporary") {
      sortedJobs = sortedJobs.filter((job) => job.JobType === "Temporary");
    }
    return sortedJobs;
  };

  const filterAndPaginateJobs = () => {
    let sortedJobs = sortJobs(jobs);
    return sortedJobs;
    // let filteredJobs = [...jobs];
    // if (jobType) {
    //   filteredJobs = filteredJobs.filter((job) => job.JobType === jobType);
    // }
    // if (datePosted) {
    //   const today = new Date();
    //   filteredJobs = filteredJobs.filter((job) => {
    //     const jobDate = new Date(job.time); // Assuming `time` is the date posted
    //     const daysDifference = (today - jobDate) / (1000 * 60 * 60 * 24); // Convert milliseconds to days

    //     if (datePosted === "Last 24 Hours" && daysDifference <= 1) {
    //       return true;
    //     } else if (datePosted === "Last 7 Days" && daysDifference <= 7) {
    //       return true;
    //     } else if (datePosted === "Last 30 Days" && daysDifference <= 30) {
    //       return true;
    //     }
    //     return false;
    //   });
    // }
    // Implement any additional filtering logic here if needed
    // return filteredJobs.slice(
    //   (currentPage - 1) * jobsPerPage,
    //   currentPage * jobsPerPage
    // );
  };
  const currentJobs = filterAndPaginateJobs().slice(
    (currentPage - 1) * jobsPerPage,
    currentPage * jobsPerPage
  );
  useEffect(() => {
    const allJobs = filterAndPaginateJobs();
    const filteredJobs = allJobs.filter(
      (job) => job.employmentType === "Hybrid"
    );
    console.log(filteredJobs);
  }, [jobType]);
  // Logic to get current jobs based on current page and settings
  // const currentJobs = filterAndPaginateJobs();
  // console.log(currentJobs);

  // console.log(currentJobs);
  // Logic to determine total number of pages
  const totalPages = Math.ceil(filterAndPaginateJobs().length / jobsPerPage);

  // Event handler to navigate to the previous page
  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Event handler to navigate to the next page
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Event handler to navigate to a specific page
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Event handler to update sorting option
  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
    setCurrentPage(1); // Reset to first page when changing sorting option
  };

  // Event handler to update items per page
  const handleJobsPerPageChange = (e) => {
    const page = e.target.value.split(" ");
    console.log(parseInt(page[1]));
    setJobsPerPage(parseInt(page[1]));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  let profileCompleted = localStorage.getItem("ProfileCompleted");

  useEffect(() => {
    if (profileCompleted === "false") {
      console.log(typeof profileCompleted);
      if (role === "Candidate") {
        navigate("/my-profile");
      } else {
        navigate("/company-profile");
      }
    }
  }, []);
  return (
    <div>
      <Helmet>
        <meta
          name="Find IT Jobs 2024 | Latest Job Listings in Canada | Skillstream Jobs"
          content="Find IT jobs for 2024 with SkillStream Jobs. Explore the latest job listings in Canada to connect with top employers and advance your tech career today."
        />
      </Helmet>
      <section class="page-title style-two">
        <div class="auto-container">
          {/*<div class="title-outer">
            <h1>Find Jobs</h1>
            <ul class="page-breadcrumb">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Jobs</li>
            </ul>
  </div>*/}
          <Toaster position="top-center" reverseOrder={false} />
          <div class="job-search-form">
            <form onSubmit={keywordSearch}>
              <div class="row">
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                  <span class="icon flaticon-search-1"></span>
                  <input
                    type="text"
                    name="field_name"
                    placeholder="Job title, keywords, or company"
                    value={jobTitle}
                    onChange={(e) => SetjobTitle(e.target.value)}
                  />
                </div>

                <div class="form-group col-lg-3 col-md-12 col-sm-12 location">
                  <span class="icon flaticon-map-locator"></span>
                  <input
                    type="text"
                    name="field_name"
                    placeholder="Country"
                    value={city}
                    onChange={(e) => SetCity(e.target.value)}
                  />
                </div>

                <div class="form-group col-lg-3 col-md-12 col-sm-12 location">
                  <span class="icon flaticon-briefcase"></span>
                  <select
                    class="chosen-select"
                    value={industry}
                    onChange={(e) => SetIndustry(e.target.value)}
                  >
                    <option value="">All Categories</option>
                    {industryData?.map((e, idx) => (
                      <option key={idx} value={e}>
                        {e}
                      </option>
                    ))}
                  </select>
                </div>

                <div class="form-group col-lg-2 col-md-12 col-sm-12 text-right">
                  <button
                    type="submit"
                    class="theme-btn btn-style-one"
                    // onClick={() => keywordSearch()}
                  >
                    Find Jobs
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section class="ls-section">
        <div class="auto-container">
          <div class="filters-backdrop"></div>

          <div class="row">
            <div class="filters-column col-lg-4 col-md-12 col-sm-12">
              <div class="inner-column">
                <div class="filters-outer">
                  <button type="button" class="theme-btn close-filters">
                    X
                  </button>

                  <div class="switchbox-outer">
                    <h4>Job type</h4>
                    <ul class="switchbox">
                      {[
                        { label: "Freelance", value: "Freelance" },
                        { label: "Full Time", value: "Full Time" },
                        { label: "Internship", value: "Internship" },
                        { label: "Part Time", value: "Part Time" },
                        { label: "Temporary", value: "Temporary" },
                      ].map((job) => (
                        <li key={job.value}>
                          <label class="switch">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setJobType((prev) => [...prev, job.value]);
                                } else {
                                  setJobType((prev) =>
                                    prev.filter((type) => type !== job.value)
                                  );
                                }
                              }}
                            />
                            <span class="slider round"></span>
                            <span class="title">{job.label}</span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div class="checkbox-outer">
                    <h4>Date Posted</h4>
                    <ul class="checkboxes">
                      <li>
                        <input id="check-f" type="checkbox" name="check" />
                        <label for="check-f">All</label>
                      </li>
                      <li>
                        <input id="check-a" type="checkbox" name="check" />
                        <label for="check-a">Last Hour</label>
                      </li>
                      <li>
                        <input id="check-b" type="checkbox" name="check" />
                        <label for="check-b">Last 24 Hours</label>
                      </li>
                      <li>
                        <input id="check-c" type="checkbox" name="check" />
                        <label for="check-c">Last 7 Days</label>
                      </li>
                      <li>
                        <input id="check-d" type="checkbox" name="check" />
                        <label for="check-d">Last 14 Days</label>
                      </li>
                      <li>
                        <input id="check-e" type="checkbox" name="check" />
                        <label for="check-e">Last 30 Days</label>
                      </li>
                    </ul>
                  </div>

                  <div class="checkbox-outer">
                    <h4>Experience </h4>
                    <ul class="checkboxes square1">
                      <li>
                        <input id="check-ba" type="checkbox" name="check" />
                        <label for="check-ba">0-1</label>
                      </li>
                      <li>
                        <input id="check-bb" type="checkbox" name="check" />
                        <label for="check-bb">1-2</label>
                      </li>
                      <li>
                        <input id="check-bc" type="checkbox" name="check" />
                        <label for="check-bc">3-4</label>
                      </li>
                      <li>
                        <input id="check-bd" type="checkbox" name="check" />
                        <label for="check-bd">5+</label>
                      </li>
                      {/*         <li>
                        <input id="check-be" type="checkbox" name="check" />
                        <label for="check-be">Mid-Senior level4</label>
                      </li>
                      <li>
                        <button class="view-more">
                          <span class="icon flaticon-plus"></span> View More
                        </button>
                      </li>*/}
                    </ul>
                  </div>

                  {/* <div class="filter-block">
                    <h4>Salary</h4>

                    <div class="range-slider-one salary-range">
                      <div class="salary-range-slider"></div>
                      <div class="input-outer">
                        <div class="amount-outer">
                          <span class="amount salary-amount">
                            $<span class="min">0</span>$
                            <span class="max">0</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                     <div class="filter-block">
                    <h4>Tags</h4>
                    <ul class="tags-style-one">
                      <li>
                        <a href="#">app</a>
                      </li>
                      <li>
                        <a href="#">administrative</a>
                      </li>
                      <li>
                        <a href="#">android</a>
                      </li>
                      <li>
                        <a href="#">wordpress</a>
                      </li>
                      <li>
                        <a href="#">design</a>
                      </li>
                      <li>
                        <a href="#">react</a>
                      </li>
                    </ul>
                  </div>*/}
                </div>

                {/*        <div class="call-to-action-four">
                  <h5>Recruiting?</h5>
                  <p>
                    Advertise your jobs to millions of monthly users and search
                    15.8 million CVs in our database.
                  </p>
                  <a href="#" class="theme-btn btn-style-one bg-blue">
                    <span class="btn-title">Start Recruiting Now</span>
                  </a>
                  <div
                    class="image jobList-image"
                    // style="background-image: url(images/resource/ads-bg-4.png);"
                  ></div>
                  </div>*/}
              </div>
            </div>

            <div class="content-column col-lg-8 col-md-12 col-sm-12">
              <div class="ls-outer">
                <button
                  type="button"
                  class="theme-btn btn-style-two toggle-filters"
                >
                  Show Filters
                </button>

                <div class="ls-switcher">
                  <div class="showing-result">
                    <div className="text">
                      Showing{" "}
                      <strong>
                        {(currentPage - 1) * jobsPerPage + 1}-
                        {currentPage * jobsPerPage}
                      </strong>{" "}
                      of <strong>{jobs.length}</strong> jobs
                    </div>
                  </div>
                  {/* <div className="ls-switcher">
                    <div className="sort-by">
                      <select
                        className="chosen-select"
                        value={sortBy}
                        onChange={handleSortByChange}
                      >
                        <option>New Jobs</option>
                        <option>Freelance</option>
                        <option>Full Time</option>
                        <option>Internship</option>
                        <option>Part Time</option>
                        <option>Temporary</option>
                      </select>

                    
                      <select
                        className="chosen-select"
                        
                        onChange={handleJobsPerPageChange}
                      >
                        <option>Show 2</option>
                        <option>Show 10</option>
                        <option>Show 20</option>
                        <option>Show 30</option>
                        <option>Show 40</option>
                        <option>Show 50</option>
                        <option>Show 60</option>
                      </select>
                    </div>
                  </div> */}
                </div>
                {jobs.length === 0 ? (
                  <p style={{ textAlign: "center" }}>No Jobs Found</p>
                ) : (
                  <>
                    {loader ? (
                      <p style={{ textAlign: "center" }}>Searching ....</p>
                    ) : (
                      currentJobs?.map((job, subIndex) => (
                        <div key={subIndex}>
                          {/*console.log(job)*/}

                          <a>
                            <div className="job-block">
                              <div className="inner-box">
                                <div className="content">
                                  <span className="company-logo">
                                    <img src={job.image} alt="" />
                                  </span>
                                  <h4>
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `/job-detail/${job.userId}/${job.jobId}`
                                        )
                                      }
                                    >
                                      {job.JobTitle}
                                    </button>
                                  </h4>
                                  <ul className="job-info">
                                    <li>
                                      <span className="icon flaticon-briefcase"></span>{" "}
                                      {job.Industry}
                                    </li>
                                    <li>
                                      <span className="icon flaticon-map-locator"></span>{" "}
                                      {job.City}, {job.Country}
                                    </li>
                                    {/* Assuming you have a createdAt field in your job data */}
                                    <li>
                                      <span className="icon flaticon-clock-3"></span>{" "}
                                      {formatDistanceToNow(
                                        new Date(job?.time),
                                        {
                                          addSuffix: true,
                                        }
                                      )}
                                    </li>
                                    <li>
                                      <span className="icon flaticon-money"></span>{" "}
                                      {job.offeredSalary}
                                    </li>
                                  </ul>
                                  <ul className="job-other-info">
                                    <li className="time">{job.JobType}</li>
                                    <li className="privacy">Permanent</li>
                                    <li className="required">Immediately</li>
                                  </ul>
                                  <button
                                    className="bookmark-btn"
                                    onClick={() => shortlistedJob(job)}
                                  >
                                    <span className="flaticon-bookmark"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      ))
                    )}
                  </>
                )}
                <nav className="ls-pagination">
                  <ul>
                    <li
                      className={`prev ${currentPage === 1 ? "disabled" : ""}`}
                    >
                      <a href="#" onClick={goToPrevPage}>
                        <i className="fa fa-arrow-left"></i>
                      </a>
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <li
                        key={i}
                        className={currentPage === i + 1 ? "active" : ""}
                      >
                        <a href="#" onClick={() => goToPage(i + 1)}>
                          {i + 1}
                        </a>
                      </li>
                    ))}
                    <li
                      className={`next ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <a href="#" onClick={goToNextPage}>
                        <i className="fa fa-arrow-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default JobList;
// const [currentPage, setCurrentPage] = useState(1);
// const jobsPerPage = 10; // Change this value to adjust the number of jobs per page

// // Logic to get current jobs based on current page
// const indexOfLastJob = currentPage * jobsPerPage;
// const indexOfFirstJob = indexOfLastJob - jobsPerPage;
// const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

// // Logic to determine total number of pages
// const totalPages = Math.ceil(jobs.length / jobsPerPage);

// // Event handler to navigate to the previous page
// const goToPrevPage = () => {
//   if (currentPage > 1) {
//     setCurrentPage(currentPage - 1);
//   }
// };

// // Event handler to navigate to the next page
// const goToNextPage = () => {
//   if (currentPage < totalPages) {
//     setCurrentPage(currentPage + 1);
//   }
// };

// // Event handler to navigate to a specific page
// const goToPage = (pageNumber) => {
//   setCurrentPage(pageNumber);
// };
