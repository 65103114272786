import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Skeleton,
  Modal,
  Input,
  Button,
  message,
  Spin,
  Empty,
  Typography,
  Tooltip,
  Dropdown,
  Collapse,
} from "antd";
import { Server } from "../../../App";
import { useNavigate } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  FileTextOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import toast, { Toaster } from "react-hot-toast";
import { FaBriefcase } from "react-icons/fa"; // Importing Font Awesome icon (for job title)
import { Typewriter } from "react-simple-typewriter";
import { motion } from "framer-motion";
import { useSpring, animated } from "@react-spring/web";
import ReactMarkdown from "react-markdown";
import DemoModal from "Components/VideoAssessment/DemoModal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";
import "./Applicants.css";
const { TextArea } = Input;
const parseTable = (tableString) => {
  // Split rows and columns, ignoring dashed lines
  const rows = tableString
    .trim()
    .split("\n")
    .filter((row) => !row.startsWith("|---"))
    .map((row) => row.split("|").map((cell) => cell.trim()));

  return rows;
};
const SkillTable = ({ skillMatchingTable }) => {
  // Parsing the input string into rows and columns
  const rows = parseTable(skillMatchingTable);

  return (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: "20px",
      }}
    >
      <thead>
        <tr>
          {rows[0].map((header, index) => (
            <th
              key={index}
              style={{ border: "1px solid #ddd", padding: "8px" }}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.slice(1).map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td
                key={cellIndex}
                style={{ border: "1px solid #ddd", padding: "8px" }}
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const Shortlisted = () => {
  const [applicants, setApplicants] = useState([]);
  const [statusFilter, setStatusFilter] = useState("Approved");
  const [filterApplicant, setFilterApplicant] = useState([]);
  const [jobTitleFilter, setJobTitleFilter] = useState("All");
  const [jobs, setJobs] = useState([]);
  const [jobFilter, setJobFilter] = useState([]);
  const [selectedApplicant, setSelectedApplicant] = useState();
  const [selectedJob, setSelectedJob] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [aiResult, setAiResult] = useState("");
  const [aiScore, setAiScore] = useState(null);
  const [userName, setUserName] = useState("");
  const [status, setStatus] = useState(false);
  const [sentLoader, setEmailLoader] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [videoResults, setVideoResults] = useState("");
  const [interviewTranscript, setInterviewTranscript] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewResultModalVisible, setIsViewResultModalVisible] =
    useState(false);
  const scoreProps = useSpring({
    opacity: aiScore !== null ? 1 : 0,
    transform: aiScore !== null ? "scale(1)" : "scale(0.8)",
    config: { duration: 500 },
  });
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [isResultModalVisible, setIsResultModalVisible] = useState(false);
  let userId = localStorage.getItem("UserId");

  const getApplicants = async () => {
    try {
      const { data } = await axios.get(
        `${Server}/api/v1/employer/jobs/allApplicants/${userId}`
      );
      const approved = data.allApplicants.jobs.map((job) => ({
        ...job,
        applicants: job.applicants.filter(
          (applicant) => applicant.applicationStatus === "Approved"
        ),
      }));
      console.log(approved);
      const sortedJobs = approved.sort(
        (a, b) => new Date(b.time) - new Date(a.time)
      );
      console.log(sortedJobs);

      setApplicants(sortedJobs);
      setFilterApplicant(sortedJobs);
      setJobs(sortedJobs);
      setJobFilter(sortedJobs);
      // console.log(data.allApplicants.jobs);
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  let profileCompleted = localStorage.getItem("ProfileCompleted");
  let role = localStorage.getItem("Role");

  useEffect(() => {
    if (profileCompleted === "false") {
      if (role === "Candidate") {
        navigate("/my-profile");
      } else {
        navigate("/company-profile");
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateStatus = async (
    JobTitle,
    applicationStatus,
    jobId,
    candidateId
  ) => {
    try {
      // console.log(JobTitle, applicationStatus, jobId, candidateId);
      let employerId = localStorage.getItem("UserId");
      let jobDetails = {
        applicationStatus,
        JobTitle,
      };
      const { data } = await axios.post(
        `${Server}/api/v1/employer/jobs/application/status/${employerId}/${candidateId}/${jobId}`,
        jobDetails
      );
      // console.log(data);
      toast.success(`${applicationStatus}ed Successfully!`);
      getApplicants();
      setStatus(true);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteApplicant = async (jobId, userId) => {
    try {
      let employerId = localStorage.getItem("UserId");
      // console.log(jobId, userId);
      const { data } = await axios.delete(
        `${Server}/api/v1/employer/jobs/applicants/delete/${employerId}/${jobId}/${userId}`
      );
      // console.log(data);
      toast.success("Deleted Successfully");
      getApplicants();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getApplicants();
  }, []);
  useEffect(() => {
    getApplicants();
  }, [aiResult, status]);

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleJobTitleFilterChange = (event) => {
    setJobTitleFilter(event.target.value);
  };

  useEffect(() => {
    setApplicants(() =>
      filterApplicant.map((job) => ({
        ...job,
        applicants: job.applicants.filter(
          (applicant) =>
            statusFilter === "All" ||
            applicant.applicationStatus === statusFilter
        ),
      }))
    );
  }, [statusFilter]);

  useEffect(() => {
    const filteredApplicants = jobFilter.filter((job) => {
      if (jobTitleFilter === "All") {
        return true;
      } else {
        return job.JobTitle === jobTitleFilter;
      }
    });
    setApplicants(filteredApplicants);
    setFilterApplicant(filteredApplicants);
  }, [jobTitleFilter]);
  const handleAnalyzeAI = (e, applicant) => {
    setIsModalVisible(true);
    setSelectedApplicant(applicant);
    setSelectedJob({
      job_title: e.JobTitle,
      company: e.companyName,
      location: e.JobType,
      // experience_required: "10+ years of experience in Java development",
      education_required: e.Qualification,
      skills_required: e.jobSkills,
      job_description: e.JobDescription,
    });
    setSelectedUser({
      basic_info: {
        full_name: applicant.name,
        email: applicant.email,
        City: applicant.city,
        Country: applicant.country,
        Province: applicant.state,
        Experience_in_Years: applicant.experience,
        Experience_level: applicant.Experience_level,
        Job_Title: applicant.jobTitle,
      },
      education: applicant.education,
      work_experience: applicant.work_experience,
      skills: applicant.skills,
    });
    setUserName(applicant.name);
  };

  // console.log(selectedUser);
  // console.log(aiResult);
  // console.log(selectedApplicant.jobId);
  const handleModalOk = async () => {
    setLoading(true);
    try {
      // let userId = localStorage.getItem("UserId");
      let bodyData = {
        jobDetails: selectedJob,
        userDetails: selectedUser,
      };
      const { data } = await axios.post(
        `${Server}/api/v1/employer/jobs/openAI/analyse/${userId}/${selectedApplicant.jobId}/${selectedApplicant.id}`,
        bodyData
      );
      // console.log(data);

      // const { result, score } = await simulateAiAnalysis(
      //   userDetails,
      //   jobDescription
      // );

      setAiResult(data.data);
      setAiScore(data.data.matching_score);
      setLoading(false);
      setIsModalVisible(false);
      setIsResultModalVisible(true);
    } catch (error) {
      message.error("Failed to analyze. Please try again."); // Error handling
      console.log("Error analyzing with AI:", error);
    } finally {
      setLoading(false);
      setSelectedApplicant();
      setSelectedJob();
      setSelectedUser();
    }
  };
  // console.log(aiResult);

  const simulateAiAnalysis = (userDetails, jobDescription) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const score = Math.floor(Math.random() * 100) + 1; // Generate a random score between 1 and 100
        resolve({
          result:
            "AI analysis result for " + jobDescription + " and " + userDetails,
          score: score,
        });
      }, 3000); // Simulate a 3-second loading time
    });
  };

  const handleModalCancel = () => {
    setIsResultModalVisible(false);
    setIsModalVisible(false);
    setUserDetails("");
    setJobDescription("");
    setAiResult("");
    setSelectedApplicant();
    setSelectedJob();
    setSelectedUser();
  };

  const handleResultModalCancel = () => {
    if (loading) {
      Modal.confirm({
        title: "Are you sure you want to close?",
        content: "You will lose the result if you close now.",
        okText: "Yes",
        cancelText: "No",
        onOk() {
          setIsResultModalVisible(false);
          setIsModalVisible(false);
          setSelectedApplicant();
          setSelectedJob();
          setSelectedUser();
        },
      });
    } else {
      setIsResultModalVisible(false);
      setIsModalVisible(false);
      setSelectedApplicant();
      setSelectedJob();
      setSelectedUser();
    }
  };
  const handleViewResult = async (applicant) => {
    // console.log(applicant);
    setIsResultModalVisible(true);
    setAiResult(applicant.fitmentSummary);
    setAiScore(applicant.matchingScore);
  };
  // download as pdf
  const parseTableMarkdown = (markdown) => {
    const lines = markdown.trim().split("\n");
    const headers = lines[0]
      .split("|")
      .map((cell) => cell.trim())
      .filter((cell) => cell);
    const rows = lines.slice(2).map((line) =>
      line
        .split("|")
        .map((cell) => cell.trim())
        .filter((cell) => cell)
    );
    return [headers, ...rows];
  };

  const downloadPDF = async () => {
    const doc = new jsPDF();
    const margin = 10;
    const pageWidth = doc.internal.pageSize.getWidth() - margin * 2;
    let yPosition = 20;

    // Title
    doc.setFontSize(18);
    doc.text("AI Analysis Result", margin, yPosition);
    yPosition += 10;

    // Add Brief Summary with Heading
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Brief Summary:", margin, yPosition);
    yPosition += 10;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const briefSummary = aiResult.brief_summary;
    const briefLines = doc.splitTextToSize(briefSummary, pageWidth);
    briefLines.forEach((line) => {
      if (yPosition + 10 > doc.internal.pageSize.height) {
        doc.addPage();
        yPosition = margin;
      }
      doc.text(line, margin, yPosition);
      yPosition += 10;
    });

    // Add Short Summary with Heading
    if (yPosition + 10 > doc.internal.pageSize.height) {
      doc.addPage();
      yPosition = margin;
    }
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Short Summary:", margin, yPosition);
    yPosition += 10;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const shortSummary = aiResult.short_summary;
    const shortLines = doc.splitTextToSize(shortSummary, pageWidth);
    shortLines.forEach((line) => {
      if (yPosition + 10 > doc.internal.pageSize.height) {
        doc.addPage();
        yPosition = margin;
      }
      doc.text(line, margin, yPosition);
      yPosition += 10;
    });

    // Add Matching Score with Heading
    if (yPosition + 10 > doc.internal.pageSize.height) {
      doc.addPage();
      yPosition = margin;
    }
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Matching Score:", margin, yPosition);
    yPosition += 10;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const aiScore = aiResult.matching_score;
    doc.text(`Score: ${aiScore}`, margin, yPosition);
    yPosition += 10;

    // Add Skill Matching Table with Heading
    const skillMatchingTableMarkdown = aiResult.skill_matching_table;
    const skillMatchingTable = parseTableMarkdown(skillMatchingTableMarkdown);

    if (Array.isArray(skillMatchingTable) && skillMatchingTable.length > 0) {
      if (yPosition + 10 > doc.internal.pageSize.height) {
        doc.addPage();
        yPosition = margin;
      }

      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text("Skill Matching Table:", margin, yPosition);
      yPosition += 10;

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.autoTable({
        startY: yPosition,
        head: [skillMatchingTable[0]], // Header
        body: skillMatchingTable.slice(1), // Rows
        margin: { top: 10 },
      });
    } else {
      console.error("Invalid skill matching table data:", skillMatchingTable);
    }

    // Save the PDF
    doc.save(`${userName}-analysis-result.pdf`);
  };
  const dowloadVideoResults = async () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text("Video Anlyse Result", 10, 10);
    doc.setFontSize(12);
    const margin = 10;
    const pageWidth = doc.internal.pageSize.getWidth() - margin * 2;
    const yStart = 20;
    let yPosition = yStart;
    const resultLines = doc.splitTextToSize(videoResults, pageWidth);

    resultLines.forEach((line, index) => {
      if (yPosition + 10 > doc.internal.pageSize.height) {
        doc.addPage();
        yPosition = margin;
      }
      doc.text(line, margin, yPosition);
      yPosition += 10;
    });
    if (aiScore !== null) {
      if (yPosition + 10 > doc.internal.pageSize.height) {
        doc.addPage();
      }
    }
    doc.save("video-result.pdf");
  };
  //sent interview email
  const sendEmail = async ({
    userId,
    candidateId,
    jobId,
    from,
    to,
    candidateName,
    JobTitle,
    companyName,
  }) => {
    setEmailLoader(true);
    try {
      let payload = {
        userId,
        candidateId,
        jobId,
        from,
        to,
        candidateName,
        JobTitle,
        companyName,
      };
      // console.log(payload);

      const { data } = await axios.post(
        `${Server}/api/v1/videoInterview/sendEmail/${userId}/${jobId}/${candidateId}`,
        payload
      );
      // console.log(data);
      toast.success("Email Invite Sent");
      setEmailLoader(false);
    } catch (error) {
      console.log(error);
      toast.error("Error in sending Interview Invite");
    }
  };
  const handleResults = (video, videoResults, i) => {
    setVideoLink(video);
    setVideoResults(videoResults);
    setInterviewTranscript(i);
  };
  const handleOpenModal = ({
    userId,
    candidateId,
    jobId,
    from,
    to,
    candidateName,
    JobTitle,
    companyName,
  }) => {
    setSelectedCandidate({
      userId,
      candidateId,
      jobId,
      from,
      to,
      candidateName,
      JobTitle,
      companyName,
    });
    // console.log(selectedCandidate);
    setIsModalOpen(true);
  };
  const handleViewModalCancel = () => {
    setIsViewResultModalVisible(false);
  };
  // const items = [
  //   {
  //     label: (
  //       <a href={videoLink} target="_blank">
  //         {" "}
  //         View Video Result
  //       </a>
  //     ),
  //     key: "0",
  //   },
  //   {
  //     label: (
  //       <a onClick={() => setIsViewResultModalVisible(true)}>View Results</a>
  //     ),
  //     key: "1",
  //   },
  //   {
  //     label: (
  //       <a href={interviewTranscript?.transcript} target="_blank">
  //         View Transcript
  //       </a>
  //     ),
  //     key: "2",
  //   },
  // ];
  // console.log(interviewTranscript.transcript);

  const items = [
    {
      label: (
        <a href={videoLink} target="_blank">
          View Video Result
        </a>
      ),
      key: "0",
    },
    {
      label: (
        <a onClick={() => setIsViewResultModalVisible(true)}>View Results</a>
      ),
      key: "1",
    },
    ...(interviewTranscript?.transcript
      ? [
          {
            label: (
              <a href={interviewTranscript.transcript} target="_blank">
                View Transcript
              </a>
            ),
            key: "2",
          },
        ]
      : []),
  ];

  return (
    <div>
      <section className="user-dashboard">
        <div className="dashboard-outer">
          <div className="upper-title-box">
            <h3>Shortlisted Resumes</h3>
            <div className="text">Ready to jump back in?</div>
          </div>
          <Toaster position="top-center" reverseOrder={false} />
          <div className="row">
            <div className="col-lg-12">
              <div className="ls-widget">
                <div className="tabs-box">
                  <div className="widget-title">
                    <h4>Applicant</h4>

                    <div className="chosen-outer">
                      <select
                        className="chosen-select"
                        value={jobTitleFilter}
                        onChange={handleJobTitleFilterChange}
                      >
                        <option value="All">All Jobs</option>
                        {jobs?.map((e, idx) => (
                          <option key={idx} value={e?.JobTitle}>
                            {e?.JobTitle}
                          </option>
                        ))}
                      </select>
                      {/*
                      <select
                        className="chosen-select"
                        value={statusFilter}
                        onChange={handleStatusFilterChange}
                      >
                        <option value="All">All Status</option>
                        <option value="pending">Pending</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                      </select>*/}
                    </div>
                  </div>

                  <div className="widget-content">
                    {applicants.length === 0 ? (
                      <Empty description="No Applicant Shortlisted" />
                    ) : (
                      applicants?.map((e, idx) => (
                        /*<div className="tabs-box" key={idx}>*/
                        <Collapse bordered={false} key={idx}>
                          <Collapse.Panel header={e.JobTitle} key={idx}>
                            <div className="aplicants-upper-bar">
                              <h6>{e.JobTitle}</h6>
                              <ul className="aplicantion-status tab-buttons clearfix">
                                <li
                                  className="tab-btn active-btn totals"
                                  data-tab="#totals"
                                >
                                  Total(s):
                                  {e?.applicants ? e?.counts?.total : 0}
                                </li>
                                <li
                                  className="tab-btn approved"
                                  data-tab="#approved"
                                >
                                  Approved:
                                  {e?.applicants ? e?.counts?.approved : 0}
                                </li>
                                <li
                                  className="tab-btn rejected"
                                  data-tab="#rejected"
                                >
                                  Rejected(s):
                                  {e?.applicants ? e?.counts?.rejected : 0}
                                </li>
                              </ul>
                            </div>

                            <div className="tabs-content">
                              <div className="tab active-tab" id="totals">
                                <div className="row">
                                  {e.applicants ? (
                                    e.applicants?.length !== 0 ? (
                                      e?.applicants?.map((i, idx) => (
                                        <div
                                          className="widget-content"
                                          key={idx}
                                        >
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <div className="candidate-block-three">
                                                <div className="inner-box">
                                                  <div className="content">
                                                    <div>
                                                      <figure className="image">
                                                        <img
                                                          src={
                                                            i?.image ||
                                                            "default-image.jpg"
                                                          }
                                                          alt="User"
                                                          style={{
                                                            width: "80px",
                                                            height: "80px",
                                                          }}
                                                        />
                                                      </figure>
                                                      <h4>
                                                        <a href="#">
                                                          {i?.name}
                                                        </a>
                                                      </h4>
                                                      <ul className="candidate-info">
                                                        <li>
                                                          <span className="icon flaticon-map-locator"></span>
                                                          {i?.country}
                                                        </li>
                                                        <br />

                                                        <li>
                                                          <FaBriefcase
                                                            className="icon"
                                                            style={{
                                                              marginRight:
                                                                "8px",
                                                            }}
                                                          />
                                                          {i?.jobTitle}
                                                        </li>
                                                        {/* <li>
                                            
                                              <a
                                                href={`mailto:${i?.email}`}
                                              >
                                                {i?.email}
                                              </a>
                                              </li>
                                            <li>
                                            {i?.mobile}
                                            </li> */}
                                                      </ul>
                                                      {/* <ul className="post-tags">
                                            <li>
                                              <motion.span>
                                                {i?.skills.join("\n \n")}
                                              </motion.span>
                                            </li>
                                          </ul> */}
                                                    </div>
                                                    <ul
                                                      className="post-tags"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      <li>
                                                        <a href="#">
                                                          <motion.div
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                            initial={{
                                                              opacity: 0,
                                                            }}
                                                            animate={{
                                                              opacity: 1,
                                                            }}
                                                            transition={{
                                                              delay: 0.3,
                                                              duration: 0.5,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                width: "10px",
                                                                height: "10px",
                                                                borderRadius:
                                                                  "50%",
                                                                backgroundColor:
                                                                  i?.applicationStatus ===
                                                                  "Approved"
                                                                    ? "green"
                                                                    : i?.applicationStatus ===
                                                                      "Rejected"
                                                                    ? "red"
                                                                    : "#FFC107",
                                                                marginRight:
                                                                  "8px",
                                                              }}
                                                            />
                                                            <motion.span
                                                              style={{
                                                                color:
                                                                  i?.applicationStatus ===
                                                                  "Approved"
                                                                    ? "green"
                                                                    : i?.applicationStatus ===
                                                                      "Rejected"
                                                                    ? "red"
                                                                    : "#FFC107",
                                                              }}
                                                            >
                                                              {
                                                                i?.applicationStatus
                                                              }
                                                            </motion.span>
                                                          </motion.div>
                                                        </a>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "20px",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      <Button
                                                        icon={
                                                          <CheckCircleOutlined />
                                                        }
                                                        type="primary"
                                                        onClick={() =>
                                                          updateStatus(
                                                            e?.JobTitle,
                                                            "Approve",
                                                            i?.jobId,
                                                            i?.id
                                                          )
                                                        }
                                                        disabled={
                                                          i?.applicationStatus ===
                                                            "Approved" ||
                                                          i?.applicationStatus ===
                                                            "Rejected"
                                                        }
                                                        style={{
                                                          marginRight: "8px",
                                                          backgroundColor:
                                                            "#4CAF50",
                                                        }}
                                                      >
                                                        Approve
                                                      </Button>
                                                      <Button
                                                        icon={
                                                          <CloseCircleOutlined />
                                                        }
                                                        type="danger"
                                                        onClick={() =>
                                                          updateStatus(
                                                            e?.JobTitle,
                                                            "Rejecte",
                                                            i?.jobId,
                                                            i?.id
                                                          )
                                                        }
                                                        style={{
                                                          marginRight: "8px",
                                                          backgroundColor:
                                                            "#FF5252",
                                                        }}
                                                        disabled={
                                                          i?.applicationStatus ===
                                                            "Approved" ||
                                                          i?.applicationStatus ===
                                                            "Rejected"
                                                        }
                                                      >
                                                        Reject
                                                      </Button>
                                                      <a
                                                        href={i?.resume}
                                                        target="_blank"
                                                      >
                                                        <Button
                                                          icon={<EyeOutlined />}
                                                          type="primary"
                                                          style={{
                                                            marginBottom: "8px", // Space between rows
                                                            backgroundColor:
                                                              "#1E88E5",
                                                          }}
                                                        >
                                                          View Resume
                                                        </Button>
                                                      </a>
                                                      <Button
                                                        icon={
                                                          <DeleteOutlined />
                                                        }
                                                        type="default"
                                                        onClick={() =>
                                                          deleteApplicant(
                                                            i?.jobId,
                                                            i?.id
                                                          )
                                                        }
                                                        style={{
                                                          backgroundColor:
                                                            "#FF9800",
                                                        }}
                                                      >
                                                        Delete
                                                      </Button>
                                                    </div>
                                                  </div>

                                                  <div className="btn-box-one">
                                                    <div className="button-container">
                                                      {!i?.fitmentSummary ? (
                                                        <Tooltip title="Analyze with AI">
                                                          <div
                                                            className="loader-one"
                                                            onClick={() =>
                                                              handleAnalyzeAI(
                                                                e,
                                                                i
                                                              )
                                                            }
                                                          >
                                                            <span className="loader__inner1"></span>
                                                            <span className="loader__inner1"></span>
                                                            <span className="loader__text1">
                                                              <SearchOutlined />
                                                            </span>
                                                          </div>
                                                        </Tooltip>
                                                      ) : (
                                                        <Tooltip title="View Analysis Results">
                                                          <div
                                                            className="square blue"
                                                            type="primary"
                                                            onClick={() =>
                                                              handleViewResult(
                                                                i
                                                              )
                                                            }
                                                            style={{
                                                              backgroundColor:
                                                                "#00BCD4",
                                                            }}
                                                          >
                                                            <FileTextOutlined />
                                                          </div>
                                                        </Tooltip>
                                                      )}

                                                      {/* Tooltip for Analyze with AI */}

                                                      {/* Tooltip for View Results */}
                                                      {!i?.interviewStatus ||
                                                      i?.interviewStatus ===
                                                        "Sent" ? (
                                                        <Tooltip title="AI Video Assessments">
                                                          <div
                                                            className="square red"
                                                            type="primary"
                                                            onClick={() =>
                                                              handleOpenModal({
                                                                userId: userId,
                                                                candidateId:
                                                                  i?.id,
                                                                jobId: e?.jobId,
                                                                from: e?.EmailAddress,
                                                                to: i?.email,
                                                                candidateName:
                                                                  i?.name,
                                                                JobTitle:
                                                                  e?.JobTitle,
                                                                companyName:
                                                                  e?.companyName,
                                                              })
                                                            }
                                                            // onClick={() =>
                                                            //   setIsModalOpen(true)
                                                            // }
                                                            style={{
                                                              marginBottom:
                                                                "8px",
                                                              backgroundColor:
                                                                "#FFC107",
                                                            }}
                                                          >
                                                            <VideoCameraOutlined />
                                                            {/*sentLoader ? (
                                                            <Spin />
                                                          ) : (
                                                            <VideoCameraOutlined />
                                                          )*/}
                                                          </div>
                                                        </Tooltip>
                                                      ) : (
                                                        <Tooltip title="View Video Results">
                                                          <div
                                                            className="square green"
                                                            type="primary"
                                                            style={{
                                                              marginBottom:
                                                                "8px",
                                                              backgroundColor:
                                                                "#1E88E5",
                                                            }}
                                                          >
                                                            <Dropdown
                                                              menu={{
                                                                items,
                                                              }}
                                                              trigger={[
                                                                "click",
                                                              ]}
                                                            >
                                                              <a
                                                                onClick={() =>
                                                                  handleResults(
                                                                    i?.video,
                                                                    i?.interviewSummary,
                                                                    i
                                                                  )
                                                                }
                                                              >
                                                                <EyeOutlined />
                                                              </a>
                                                            </Dropdown>
                                                          </div>
                                                        </Tooltip>
                                                      )}

                                                      {/* Tooltip for View Video Results */}
                                                    </div>
                                                  </div>

                                                  {/* <div className="btn-box-one">
                                        <div className="button-container">
                                          <Button
                                            icon={<EyeOutlined />}
                                            type="primary"
                                            onClick={() =>
                                              navigate(`/resume/${i?.userId}`)
                                            }
                                            style={{
                                              marginBottom: "8px", // Space between rows
                                              backgroundColor: "#1E88E5",
                                            }}
                                          >
                                            View Resume
                                          </Button>
                                          <Button
                                            icon={<RobotOutlined />}
                                            type="primary"
                                            onClick={handleAnalyzeAI}
                                            style={{
                                              marginBottom: "8px",
                                              backgroundColor: "#9C27B0",
                                            }}
                                          >
                                            Analyze with AI
                                          </Button>
                                          <Button
                                            icon={<VideoCameraOutlined />}
                                            type="primary"
                                            onClick={() =>
                                              navigate(
                                                `/video-assessment/${i?.userId}`
                                              )
                                            }
                                            style={{
                                              marginBottom: "8px",
                                              backgroundColor: "#FFC107",
                                            }}
                                          >
                                            AI Video Assessments
                                          </Button>
                                          <Button
                                            icon={<FileTextOutlined />}
                                            type="primary"
                                            onClick={() =>
                                              navigate(
                                                `/results/${i?.userId}`
                                              )
                                            }
                                            style={{
                                              backgroundColor: "#00BCD4",
                                            }}
                                          >
                                            View Results
                                          </Button>
                                        </div>
                                      </div> */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    ) : (
                                      <Empty description="No Applicant Shortlisted" />
                                    )
                                  ) : (
                                    <Empty description="No Applicant Shortlisted" />
                                  )}
                                </div>
                              </div>
                            </div>
                          </Collapse.Panel>
                        </Collapse>
                        /*</div> */
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="copyright-text">
        <p>© 2024 Skill Stream. All Right Reserved.</p>
      </div>
      {isModalOpen && (
        <DemoModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedCandidate={selectedCandidate}
        />
      )}
      {/* Modal for AI Analysis */}
      {/* Modal for AI Analysis */}
      <Modal
        title="Analyze with AI"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleResultModalCancel}
        okText="Analyze"
        cancelText="Cancel"
        centered
        styles={{ textAlign: "center" }}
      >
        <div>
          <div>
            <label>User Details</label>
            <TextArea
              placeholder="User Details"
              value={JSON.stringify(selectedUser)}
              onChange={(e) => setUserDetails(e.target.value)}
              className="custom-textarea" // Add a custom class
              style={{ marginBottom: 10, height: "100px", overflowY: "scroll" }}
              disabled
            />
          </div>
          <div>
            <label>Job Description</label>
            <TextArea
              placeholder="Job Description"
              rows={4}
              value={JSON.stringify(selectedJob)}
              onChange={(e) => setJobDescription(e.target.value)}
              className="custom-textarea" // Add a custom class
              style={{ marginBottom: 10, height: "100px", overflowY: "scroll" }}
              disabled
            />
          </div>
          {loading && (
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <Spin size="large" />
            </div>
          )}
        </div>
      </Modal>

      {/* Modal for AI Analysis Result */}
      <Modal
        title="AI Analysis Result"
        open={isResultModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="download" onClick={() => downloadPDF()}>
            Download as PDF
          </Button>,
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
        ]}
        centered
        styles={{ textAlign: "center" }}
      >
        {/* <div style={{ marginBottom: 20 }}>
          <Typewriter
            words={[aiResult]}
            loop={1}
            cursor
            cursorStyle="_"
            typeSpeed={5}
            // deleteSpeed={50}
            delaySpeed={100}
          />
        </div>
        {aiScore !== null && (
          <animated.div style={scoreProps} className="score-box">
            <span>Score:</span> {aiScore}
          </animated.div>
       )}*/}
        <Spin spinning={loading}>
          <div id="modal-content">
            {/* <ReactMarkdown style={{ marginBottom: 20 }}>
            {aiResult?.brief_summary}
          </ReactMarkdown>
          <ReactMarkdown style={{ marginBottom: 20 }}>
            {aiResult?.matching_score}
          </ReactMarkdown>
          <ReactMarkdown style={{ marginBottom: 20 }}>
          {aiResult?.short_summary}
        </ReactMarkdown> */}
            <label style={{ marginBottom: "10px", fontWeight: "bolder" }}>
              Brief Summary:
            </label>
            <Typography style={{ marginBottom: "20px" }}>
              {aiResult?.brief_summary}
            </Typography>
            <label style={{ marginBottom: "10px", fontWeight: "bolder" }}>
              Short Summary:
            </label>
            <Typography style={{ marginBottom: "20px" }}>
              {" "}
              {aiResult?.short_summary}
            </Typography>
            <label style={{ marginBottom: "10px", fontWeight: "bolder" }}>
              Skill Matching:
            </label>
            <SkillTable skillMatchingTable={aiResult.skill_matching_table} />
            {aiScore !== null && (
              <animated.div style={scoreProps} className="score-box">
                <span>Score:</span> {aiScore}
              </animated.div>
            )}
          </div>
        </Spin>
      </Modal>

      <Modal
        title="AI Analysis Result"
        open={isViewResultModalVisible}
        onCancel={handleViewModalCancel}
        footer={[
          <Button key="download" onClick={() => dowloadVideoResults()}>
            Download as PDF
          </Button>,
          <Button key="cancel" onClick={handleViewModalCancel}>
            Cancel
          </Button>,
        ]}
        centered
        styles={{ textAlign: "center" }}
      >
        <div id="modal-content">
          <ReactMarkdown>{videoResults}</ReactMarkdown>
        </div>
      </Modal>
    </div>
  );
};

export default Shortlisted;

// const [data, setData] = useState([]);
// const params = useParams();
// const navigate = useNavigate();
// let profileCompleted = localStorage.getItem("ProfileCompleted");
// let role = localStorage.getItem("Role");
// useEffect(() => {
//   if (profileCompleted === "false") {
//     console.log(typeof profileCompleted);
//     if (role === "Candidate") {
//       navigate("/my-profile");
//     } else {
//       navigate("/company-profile");
//     }
//   }
// }, []);
// let userId = localStorage.getItem("UserId");
// const getshortlistedResumes = async () => {
//   try {
//     const { data } = await axios.get(
//       `${Server}/api/v1/employer/shortlistResume/view/${userId}`
//     );
//     console.log(data);
//     setData(data.shortlisted);
//   } catch (error) {
//     console.log("Error: ", error);
//   }
// };
// useEffect(() => {
//   getshortlistedResumes();
// }, []);
// useEffect(() => {
//   // Scroll to the top when navigation occurs
//   window.scrollTo(0, 0);
// }, []);
// const deleteShortlisrted = async (Id) => {
//   try {
//     console.log(Id);
//     const { data } = await axios.delete(
//       `${Server}/api/v1/employer/shortlistResume/delete/${userId}/${Id}`
//     );
//     console.log(data);
//     toast.success("Deleted Successfully");
//     setData((prevData) => prevData.filter((item) => item.userId !== Id));
//   } catch (error) {
//     console.log(error);
//   }
// };
